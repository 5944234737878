import Image from '../image'
import React from 'react'
import { SpeakerWaveIcon } from '@heroicons/react/24/outline'

export default function FilePreview({ asset, width=400, height='auto', name="fileDe" }) {
  const type = asset?.[name]?.generalType

  return (
    <div className="w-full h-full rounded-md">
      { !asset?.[name] && (
        <div className="p-4">No Preview.</div>
      )}
      { type === 'image' && (
        <Image
          className="w-full h-full rounded-md relative z-10"
          imageKey={asset?.[name]?.key}
          filename={asset?.[name]?.filename}
          width={112}
          height={112}
          title={asset?.titleDe}
        />
      )}
      { type === 'audio' && (
        <div className="relative w-full h-full flex flex space-x-2 items-center justify-center bg-gray-300 z-10 rounded-md">
          <SpeakerWaveIcon className="w-6 h-6"/>
        </div>
      )}
    </div>
  )
}
