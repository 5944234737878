import React, { useState } from 'react'

let timeout

export default function TableSearch({ setSearchQuery }) {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = e => {
    const query = e.target.value || null
    setInputValue(query)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearchQuery(query)
    }, 500)
  }

  return (
    <input type="text" value={inputValue} onChange={handleInputChange} className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 border-gray-300 mb-4" placeholder="Type to search..." autoFocus autoCorrect="off"/>
  )
}
