const fragment = `
  __typename
  id
  editingStatus
  updater {
    id
    displayName
  }
  updatedAt
  publishedAt
  publisher {
    id
    displayName
  }
`
export default `
  ...on Recipe {
    ${fragment}
  }
  ...on Post {
    ${fragment}
  }
  ...on Page {
    ${fragment}
  }
  ...on Category {
    ${fragment}
  }
  ...on Series {
    ${fragment}
  }
  ...on CookbookPost {
    ${fragment}
  }
`
