import React, { useContext, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

import Button from '../button'
import NotificationContext from '../../components/notifications/context'
import { navigate } from 'gatsby'
import recordPublishFragment from '../../fragments/publish-records'

const CREATE_RECORD_MUTATION = gql`
  mutation CreateRecordMutation($type: String!) {
    createRecord(input: { type: $type }) {
      record {
        ${recordPublishFragment}
      }
    }
  }
`

export default function RecordCreateButton({ title="Create", type, redirectOnSuccessTo }) {
  const { addNotification } = useContext(NotificationContext)
  const [create, { loading: creating }] = useMutation(CREATE_RECORD_MUTATION, {
    onError: (err) => {
      console.error(err)
      addNotification({ title: `Error creating ${type}`, message: `The ${type} could not be created.`, type: 'error' })
    },
    onCompleted: (data) => {
      addNotification({ title: `Successfuly created ${type}`, message: `The ${type} was successfuly created.`, type: 'success' })
      navigate(redirectOnSuccessTo.replace(':id', data?.createRecord?.record?.id))
    }
  })

  return useMemo(() => (
    <Button
      title={title}
      variant="indigo"
      className="w-full"
      loading={creating}
      onClick={() => create({ variables: { type }})}
    />
  ), [title,, create, creating, type])
}
