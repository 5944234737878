import coverImage from './cover-image'

export default `
  id
  titleDe
  slugDe
  coverImage {
    ${coverImage}
  }
  editingStatus
  updatedAt
  updater {
    id
    displayName
  }
`
