import React from 'react'
import { classNames } from '../../helper'

const StatusBadge = ({ status }) => {
  return (
    <span className={classNames(
      status === 'Draft' && 'bg-yellow-100 text-yellow-600 border-yellow-400',
      status === 'Changed' && 'bg-blue-100 text-blue-600 border-blue-400',
      status === 'Published' && 'bg-green-100 text-green-600 border-green-400',
      status === 'Approved' && 'bg-green-100 text-green-600 border-green-400',
      "uppercase inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-semibold border"
    )}>{status}</span>
  )
}

export default StatusBadge

// bg-indigo-100 text-indigo-800
